<template>
  <div>
    <app-header :routes="routes" />
    <Main>
      <a-row :gutter="15">
        <a-col :xs="24">
            <a-col :xs="24" class="table-search price-category-items-search">
              <a-row :gutter="[12,0]">
                <a-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6" :xxl="6">
                  <select-field
                    :label="$t('views.suggestedPrices.country')"
                    v-model:fieldValue="currentCountry"
                    :options="countries"
                    :enableSearch="true"
                  />
                </a-col>
                <a-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6" :xxl="6">
                  <select-field
                    :label="$t('views.suggestedPrices.category')"
                    v-model:fieldValue="currentCategory"
                    :options="categoryOptions"
                    :defaultActiveFirstOption="true"
                    :enableSearch="false"
                  />
                </a-col>
              </a-row>
            </a-col>
          <sdCards headless bodypadding="0px">
            <a-row type="flex">
              <a-col :xs="24">
                <suggested-prices-table-form
                  :categoryItems="categoryItems"
                  :suggestedPrices="suggestedPrices"
                  :currentCountry="currentCountry"
                  :key="currentCountry + '_' + currentCategory"
                />
              </a-col>
            </a-row>
          </sdCards>
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script>
import {
  computed, defineComponent, ref, watch
} from 'vue';
import {useStore} from 'vuex';
import {Main} from '@/components/shared/styledComponents/Main';
import _ from 'lodash';
import {useI18n} from 'vue-i18n';
import SuggestedPricesTableForm from '@/components/priceLists/SuggestedPricesTableForm';
import SelectField from '@/components/shared/fields/Select';

export default defineComponent({
  name: 'PriceCategories',
  components: {
    Main,
    SuggestedPricesTableForm,
    SelectField
  },
  setup() {
    const {t} = useI18n();
    const routes = [{
      name: 'BackOfficeSuggestedPrices',
      breadcrumbName: 'views.suggestedPrices.title'
    }];

    const {dispatch, getters} = useStore();

    const categoryTypes = computed(() => _.filter(getters.categoryTypes, (type) => type !== 'hs_code_list'));
    const categoryOptions = computed(() => {
      return _.map(categoryTypes.value, (type) => {
        return {label: t(`models.priceCategory.attributes.categoryTypes.${type}`), value: type};
      });
    });

    const currentCategory = ref('');
    const currentCountry = ref('');
    const categoryItems = computed(() => getters.categoryItems);
    const suggestedPrices = computed(() => getters.suggestedPrices);
    watch([currentCategory, currentCountry], async () => {
      if (currentCategory.value && currentCountry.value) {
        await dispatch('toggleLoading', true);
        await dispatch('getCategoryItems', {
          [_.snakeCase('categoryType')]: currentCategory.value,
          status: 'active'
        });
        await dispatch('getSuggestedPrices', {
          [_.snakeCase('categoryType')]: currentCategory.value,
          [_.snakeCase('countryCode')]: currentCountry.value
        });
        await dispatch('toggleLoading', false);
      }
    });

    const countries = computed(() => getters.countries);
    const initialSetup = async () => {
      await dispatch('toggleLoading', true);
      await dispatch('getCategoryTypes', {[_.snakeCase('prefilledOnly')]: true});
      await dispatch('getCountries');
      await dispatch('toggleLoading', false);
    };

    watch(categoryOptions, (options) => {
      if (options) currentCategory.value = options[0].value;
    });

    watch(countries, (options) => {
      if (options) currentCountry.value = options[0].value;
    });

    initialSetup();

    return {
      routes,
      categoryOptions,
      countries,
      currentCategory,
      currentCountry,
      categoryItems,
      suggestedPrices
    };
  }
});
</script>

<style lang="scss" scoped>
.ant-select {
  width: 100%;
}

.ant-card-body {
  padding: 0px !important
};
</style>
