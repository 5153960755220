import _ from 'lodash';
/**
 * Display server errors inline in table form
 * @param  {array} errors array of errors
 * @param  {object} formRef ref of form
 */
export const inlineTableErrors = async (errors, formRef) => {
  _.forEach(errors, (value, index) => {
    _.forEach(value, (errors, key) => {
      const fieldIndex = _.findIndex(formRef.value.fields, ({fieldName}) => {
        return _.isEqual(fieldName, [parseInt(index), _.camelCase(key)]);
      });
      if (!formRef.value.fields[fieldIndex]) {
        formRef.value.fields[fieldIndex].validateMessage = errors[0];
        formRef.value.fields[fieldIndex].validateState = 'error';
      }
    });
  });
};
