<template>
  <FormValidationWrap>
    <VerticalFormStyleWrap>
      <table-style-wrapper>
        <table-wrapper class="table-responsive">
          <a-form
            ref="formRef"
            name="sDash_validation-form"
            layout="vertical"
            :model="prices"
            :validateOnRuleChange="false"
          >
            <a-table
              :columns="columns"
              :data-source="prices"
              :pagination="false"
              :rowKey="(record) => $_.indexOf(prices, record)"
            >
              <template #title><h3 class="float-right mb-0 pb-30">{{$t('messages.help.usd')}}</h3></template>
              <template #itemName="{record}">
                {{$_.find(categoryItems, (item) => item._jv.id === record.priceCategoryItemId)?.itemName}}
              </template>
              <template v-for="col in ['suggestedMin', 'suggestedPercent']" :key="col" v-slot:[col]="{record}">
                <number-field
                  v-if="prices[$_.indexOf(prices, record)]['active']"
                  class="editable-table-cell hide-number-handler"
                  :rules="fieldRules(record)"
                  size="small"
                  :max="col === 'suggestedPercent' ? 100 : undefined"
                  :field-value="prices[$_.indexOf(prices, record)][col]"
                  @update:fieldValue="inputChanged(col, $_.indexOf(prices, record), $event)"
                  :fieldKey="[$_.indexOf(prices, record), col]"
                />
                <template v-else>
                  <a-tooltip :title="$t('messages.help.archivedPriceCategory')">
                    {{prices[$_.indexOf(prices, record)][col]}}
                  </a-tooltip>
                </template>
              </template>
            </a-table>

            <div class="sDash_form-action">
              <span class="float-button-right display-inline">
                <a-button
                  class="btn-signin mr-10"
                  size="small"
                  type="primary"
                  :loading="loading"
                  :disabled="loading"
                  @click="submitForm"
                >
                  <span>{{$t('actions.save')}}</span>
                </a-button>
              </span>
            </div>
          </a-form>
        </table-wrapper>
      </table-style-wrapper>
    </VerticalFormStyleWrap>
  </FormValidationWrap>
</template>

<script>
import {
  computed, defineComponent, ref, toRefs, watch
} from 'vue';
import _ from 'lodash';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import VueTypes from 'vue-types';
import {TableStyleWrapper} from '@/components/tables/style.js';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import {VerticalFormStyleWrap, FormValidationWrap} from '@/components/shared/form/Style';
import NumberField from '@/components/shared/fields/Number';
import {
  requiredNumber, integer, nonNegative
} from '@/helpers/validationRules';
import {inlineTableErrors} from '@/helpers/inlineServerErrorsInTable';
import {scrollToFirstError} from '@/helpers/scrollToFirstErrorInForm';

export default defineComponent({
  name: 'SuggestedPricesTableForm',
  components: {
    TableStyleWrapper,
    TableWrapper,
    VerticalFormStyleWrap,
    FormValidationWrap,
    NumberField
  },
  props: {
    categoryItems: VueTypes.array.def([]),
    suggestedPrices: VueTypes.array.def([]),
    currentCountry: VueTypes.string.def('')
  },
  setup(props) {
    const {t} = useI18n();

    const {categoryItems, suggestedPrices} = toRefs(props);

    const prices = ref([]);
    const setPrices = () => {
      _.forEach(categoryItems.value, (item) => {
        let price;
        if (!_.isEmpty(suggestedPrices.value)) {
          price = _.find(suggestedPrices.value, ({_jv}) => _jv.relationships.priceCategoryItem.data.id === item._jv.id);
        }
        prices.value.push({
          priceCategoryItemId: item._jv.id,
          suggestedMin: price ? price.suggestedMin : 0,
          suggestedPercent: price ? price.suggestedPercent : 0,
          id: price ? price._jv.id : undefined,
          active: !item.deletedAt
        });
      });
    };

    watch([categoryItems, suggestedPrices], () => {
      if (categoryItems.value) {
        prices.value = [];
        setPrices();
      }
    }, {immediate: true, deep: true});

    const inputChanged = (key, index, value) => prices.value[index][key] = value;

    const columns = computed(() => [
      {
        title: t('models.suggestedPrice.attributes.priceCategoryItemId'),
        dataIndex: 'priceCategoryItemId',
        slots: {customRender: 'itemName'}
      },
      {
        title: t('models.suggestedPrice.attributes.suggestedMin'),
        dataIndex: 'suggestedMin',
        slots: {customRender: 'suggestedMin'}
      },
      {
        title: t('models.suggestedPrice.attributes.suggestedPercent'),
        dataIndex: 'suggestedPercent',
        slots: {customRender: 'suggestedPercent'}
      },
      {
        title: ''
      }
    ]);

    const formRef = ref();

    const loading = ref(false);
    const {dispatch} = useStore();
    const submitForm = (e) => {
      e.preventDefault();
      formRef.value
        .validate()
        .then(async () => {
          loading.value = true;
          const data = _.map(prices.value, (price) => {
            const formattedPrice = {};
            _.forEach(price, (value, key) => {
              if (key !== 'active') formattedPrice[_.snakeCase(key)] = value;
            });
            return formattedPrice;
          });
          const errors = await dispatch('updateSuggestedPrices', {[_.snakeCase('pricesAttributes')]: data, [_.snakeCase('countryCode')]: props.currentCountry});
          loading.value = false;
          if (errors) {
            inlineTableErrors(errors[0], formRef);
          }
        }).catch(({errorFields}) => {
          scrollToFirstError(formRef, errorFields);
        });
    };

    const fieldRules = (item) => {
      if (_.find(categoryItems.value, (record) => record._jv.id === item.priceCategoryItemId)?.mandatory) {
        return [requiredNumber, integer, nonNegative];
      } else {
        return [integer, nonNegative];
      }
    };

    return {
      formRef,
      integer,
      requiredNumber,
      columns,
      loading,
      prices,
      fieldRules,
      submitForm,
      inputChanged
    };
  }
});
</script>
